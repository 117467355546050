(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("_"));
	else if(typeof define === 'function' && define.amd)
		define("DeviceList", ["React", "antd", "_"], factory);
	else if(typeof exports === 'object')
		exports["DeviceList"] = factory(require("React"), require("antd"), require("_"));
	else
		root["DeviceList"] = factory(root["React"], root["antd"], root["_"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__) {
return 